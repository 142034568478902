<!--
  Format des bannières : 2732x832 (ou proportionnel)
  
  ==================================================
  Pour ajouter un événement à venir :
    1. dans data(), puis eventsComing:, rajouter dans la liste un objet de la forme ci-dessous (copier les accolades aussi et ne pas oublier de rajouter une virgule entre chaque objet du tableau)
    {
      redirect: '/truc',
      image: 'image.png'
    }
    2. remplacer truc dans '/truc' par quelque chose qui caractérise l'image (ATTENTION : ce nom doit être unique) (ex: '/bapteme2023')
    3. remplacer image.png dans 'image.png' par le nom de l'image dans le dossier 'front/src/assets/activites/'
    4. ouvrir le fichier index.js dans le dossier 'front/src/router'
    5. dans la liste de la variable routes, rajouter un objet de la forme ci-dessous :
    {
      path: '/bapteme2023',
      name: 'Bapteme',
      beforeEnter() {
        window.location = "https://www.google.com/"
      }
    }
    6. remplacer bapteme2023 dans path:'/bapteme2023' par le nom donné à l'étape 2
    7. remplacer Bapteme dans name: 'Bapteme' par ce que vous voulez
    8. remplacer l'url "https://www.google.com/" par l'url désiré (un lien vers un Google Form par exemple)

  =================================================
  Pour ajouter un événement terminé :
  1. dans data(), puis endedEvents:, rajouter dans la liste un objet de la forme ci-dessous (copier les accolades aussi et ne pas oublier de rajouter une virgule entre chaque objet du tableau)
    {
      image: 'image.png'
    }
  2. remplacer image.png dans 'image.png' par le nom de l'image dans le dossier 'assets/activites/'
-->

<template>
  <v-container>
    <v-img
      src="@/assets/activites/evenements_titre.png"
      class="mx-auto mb-8"
      max-width="500"
    ></v-img>

    <v-row>
      <v-col class="mx-auto venir"> À VENIR </v-col>
    </v-row>
    <div class="barreG mx-auto"></div>

    <div v-for="(event, index) in eventsComing" :key=index>
      <a :href="event.redirect">
        <v-img
          :src="getImage(event.image)"
          class="mx-auto mb-3 btnActivite"
        ></v-img>
      </a>
    </div>


    <v-row>
      <v-col class="mx-auto termine mt-8"> TERMINÉS </v-col>
    </v-row>
    <div class="barreG mx-auto"></div>

    <div v-for="(event, index) in endedEvents" :key=index>
        <v-img
          :src="getImage(event.image)"
          class="mx-auto mb-1"
        ></v-img>
    </div>



  </v-container>
</template>

<script>
export default {
  data() {
    return {
      eventsComing: [
        {
          redirect: '/activites',
          image: "SautParachute2022.png"
        },
        {
          redirect: '/activites',
          image: "Bapteme.png"
        }
      ],
      endedEvents: [
        {
          image: "SautParachute.png"
        },
        {
          image: "BikeAndRun.png"
        }
      ]
    }
  },
  methods: {
    getImage(image){
      return require("@/assets/activites/" + image)
    }
  }
};
</script>

<style>
.btnActivite {
  transition: all 400ms;
}

.btnActivite:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.barreG {
  height: 1px;
  width: 50px;
  background-color: #193154;
  align-content: center;
  margin-bottom: 20px;
}
.venir, .termine {
  font-size: 30px;
  color: #193154;
  font-weight: 100;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
}
</style>